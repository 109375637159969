import logo from './logo.svg';
import './App.css';
import React from 'react';
import {ReactComponent as ReactLogo} from './logo3.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
   
        <div className="App-logo" alt="logo" >
      <ReactLogo />
</div>
      
      </header>
    </div>
  );
}

export default App;
